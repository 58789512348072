import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const EvFaqHeader = () => {
  return (
    <div className="main-yellow-bg">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h1>
              Frequently Asked Questions About Our Explainer Video Service
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EvFaqHeader
