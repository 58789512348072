import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row, Accordion, Card } from "react-bootstrap"
import scrollTo from 'gatsby-plugin-smoothscroll';

const EvFaqContent = () => {
  return (
    <section className="evfaq-content">
      <Container>
        <Row>
          <Col lg={3} className="d-none d-lg-block d-xl-block">
            <div className="evquick-link">
              <button onClick={() => scrollTo('#pricing')} className="q-link">Pricing</button>
              <button onClick={() => scrollTo('#timeline')} className="q-link">Timeline</button>
              <button onClick={() => scrollTo('#location')} className="q-link">Location</button>
              <button onClick={() => scrollTo('#process')} className="q-link">Process</button>
              <button onClick={() => scrollTo('#type')} className="q-link">Type</button>
              <button onClick={() => scrollTo('#script-concept')} className="q-link">script/Concept</button>
              <button onClick={() => scrollTo('#voice-over')} className="q-link">Voice over</button>
              <button onClick={() => scrollTo('#miscellaneous')} className="q-link">Miscellaneous</button>                            
            </div>
          </Col>
          <Col lg={9}>
            <div className="evfaq-main">
              <Accordion>
                <h2 id="pricing">Pricing</h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="0"
                    >
                      What is the price of an explainer video?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        The cost for our videos depends on the complexity, type
                        and duration of the video. To give you a rough idea,
                        these are the average prices:
                      </p>
                      <ul>
                        <li>
                          <p className="faq-list">
                            The cost for whiteboard - <strong>$2300</strong> to
                            <strong>$3000</strong> for 60 seconds
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            The cost for a 2D animated explainer video -
                            <strong>$3000</strong> to <strong>$4500</strong> for
                            60 seconds
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            The cost for a 3D animated explainer video -
                            <strong>$6000</strong> to <strong>$9000</strong> for
                            60 seconds
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            The cost for a live action video -
                            <strong>$6000</strong> to <strong>$15,000</strong>
                            for 60 seconds
                          </p>
                        </li>
                      </ul>
                      <p>
                        To To get a more detailed pricing - &nbsp; &nbsp;
                        <Link to="/contact" className="btn">
                          Contact us now
                        </Link>
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="1"
                    >
                      What are the services included in the cost for an
                      explainer video?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>The services included in the cost are:</p>
                      <ul>
                        <li>
                          <p className="faq-list">Script</p>
                        </li>
                        <li>
                          <p className="faq-list">Storyboard</p>
                        </li>
                        <li>
                          <p className="faq-list">Illustration</p>
                        </li>
                        <li>
                          <p className="faq-list">Animation</p>
                        </li>
                        <li>
                          <p className="faq-list">Voice over</p>
                        </li>
                        <li>
                          <p className="faq-list">Background music</p>
                        </li>
                        <li>
                          <p className="faq-list">Post production</p>
                        </li>
                      </ul>
                      <p>
                        You can
                        <Link to="/case-studies/" className="faq-link">
                          check out our process
                        </Link>
                        to see it in detail.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="2"
                    >
                      Is the pricing on a per second basis?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Yes, it is on a per second basis. We suggest you keep
                        the duration as short as possible for an explainer
                        video. An ideal duration would be
                        <strong>60 to 90 seconds</strong> unless a longer
                        duration is necessary.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="3"
                    >
                      How much do we charge for a video if you provide us with
                      the script?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>Got your own script? Great!</p>
                      <p>
                        Our Creative Director will go through the script you
                        would be sharing with us and gauge the effort required
                        for the video. We will get back to you with the cost
                        based on the complexity of the script.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="4"
                    >
                      What are the terms of payment?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        We will start the project after an advance payment of
                        50% is made, and the remaining after the final delivery.
                      </p>
                      <p>
                        If you require a milestone based payment, get in touch
                        with our Account Managers and we can help you out.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="5"
                    >
                      Do we provide discounts?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <p>
                        We give discounts on the basis of the
                        <strong>number of videos</strong> in a project and on
                        the basis of the <strong>duration</strong>.
                      </p>
                      <p>
                        If you have a series of videos we can give discounts
                        based on the number of reusable assets. For example, if
                        you plan to use the same character illustrations for
                        multiple videos then it makes sense to give a discount
                        as there is less effort from our part.
                      </p>
                      <p style={{ marginBottom: "0" }}>
                        The longer the duration of the videos, the lesser will
                        be the <strong>cost per second</strong> of the video.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="timeline" class="secondary-title">
                  TimeLine
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="6"
                    >
                      How much time will it take to create an explainer video?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <p>It depends on the type of video you require. </p>
                      <p>
                        A <strong>whiteboard video</strong> will require
                        <strong>2 to 3 weeks</strong> for execution whereas a
                        <strong>2D video</strong> will require
                        <strong>3 to 4 weeks</strong> for execution.
                      </p>
                      <p>
                        A <strong>3D video</strong> will take
                        <strong>4 to 5 weeks</strong> or more depending on the
                        complexity of the video.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="7"
                    >
                      Why does the video take so much time to create?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <p>
                        We have a tried and tested process that we follow to
                        ensure a quality video for you.
                      </p>
                      <p> The process includes:</p>
                      <ul>
                        <li>
                          <p className="faq-list">
                            Understanding your
                            <strong>marketing requirements</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Creating a <strong>concept</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Preparing a <strong>script</strong> for the video
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Sketching a <strong>storyboard</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Drawing the <strong>illustrations</strong> for the
                            video
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            <strong>Animating</strong> the illustrations
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Recording the <strong>voiceover</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            Adding the <strong>SFX and VFX </strong> to enhance
                            quality
                          </p>
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="8"
                    >
                      Can the video be delivered in a shorter duration?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <p>
                        If you provide a genuine cause, we will try our best to
                        speed up the timeline by re-allocating more resources
                        for the project.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="location" class="secondary-title">
                  Location
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="9"
                    >
                      Will we be able to create a video for you even if you are
                      from a different state/ country?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <p>
                        Yes, even if you are at the other end of the Earth, we
                        can create an explainer video for you, provided you are
                        accessible via phone, email, Skype, Zoom or any other
                        conference apps. If you can provide us with a timely
                        feedback on each stage in our process, we can deliver
                        the video on time for you. Distance hasn’t been an issue
                        for us till date. We have worked with many international
                        clients who are happy with our services.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="process" class="secondary-title">
                  Process
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="10"
                    >
                      Do we follow a process to create these beautiful explainer
                      videos?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      <p>
                        Yes, we do. You can find our complete process
                        <Link
                          to="/explainer-video/#process"
                          className="faq-link"
                        >
                          here
                        </Link>
                        . For more information you can contact our account
                        manager in the chat box below.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="11"
                    >
                      What are our policies regarding revision and feedbacks?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      <p>
                        We provide you with 2 iterations at each stage of our
                        video creation process.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="type" class="secondary-title">
                  Type
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="12"
                    >
                      What are the different types of video services that we
                      provide?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="12">
                    <Card.Body>
                      <p>There are mainly four different types of videos:</p>
                      <ul>
                        <li>
                          <p className="faq-list">
                            <Link
                              to="/whiteboard-video-production/"
                              target="_blank"
                              className="faq-link"
                            >
                              Whiteboard videos
                            </Link>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">2D animated videos</p>
                        </li>
                        <li>
                          <p className="faq-list">3D animated videos</p>
                        </li>
                        <li>
                          <p className="faq-list">live action videos</p>
                        </li>
                      </ul>
                      <p>
                        2D animated videos are further divided into object based
                        2D video, character based 2D video, motion graphics,
                        stop motion videos, etc.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="script-concept" class="secondary-title">
                  Script/Concept
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="13"
                    >
                      Do we provide you with a script or concept?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="13">
                    <Card.Body>
                      <p>
                        Yes, we do. We help you out from scratch. Just give us
                        an idea and your marketing objectives and we will create
                        a story out of it.
                      </p>
                      <p>
                        We start by providing you with multiple approaches for
                        the video. Once you confirm on one of the approaches, we
                        share multiple concepts with you.
                      </p>
                      <p>
                        After confirming one of the concepts, we move forward to
                        the storyline. Once the storyline is confirmed, we
                        provide you with a draft of the script. After the
                        necessary edits, we provide you with a final script.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="14"
                    >
                      Is every detail covered in the script?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      <p>
                        We schedule a call with you and our Creative team at the
                        concept stage to understand your requirement in detail.
                        We provide you with 2 iterations to create the final
                        script which will include all the relevant details.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="voice-over" class="secondary-title">
                  Voice Over
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="15"
                    >
                      Is voice over included in the cost?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="15">
                    <Card.Body>
                      <p>Yes it is. One voice over is included in the cost.</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="16"
                    >
                      Do we provide voice over in different languages?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="16">
                    <Card.Body>
                      <p>
                        Yes, we do. We have a roster of voice over artists from
                        around the world and in different dialects and accents.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="17"
                    >
                      Do we help with translating?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="17">
                    <Card.Body>
                      <p>
                        Yes, if it is in English or any other Indian languages.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="18"
                    >
                      Do we provide subtitles?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="18">
                    <Card.Body>
                      <p>Sure we do. In the language you prefer.</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <h2 id="miscellaneous" class="secondary-title">
                  Miscellaneous
                </h2>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="19"
                    >
                      What will be included in the final delivery?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="19">
                    <Card.Body>
                      <p>The following are the final deliverables:</p>
                      <ol>
                        <li>
                          <p className="faq-list">
                            The <strong>final explainer video</strong> in MP4 or
                            MOV format in HD (1080p or above).
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            A <strong>480p version of the video</strong> that
                            has been compressed so it can be shared on{" "}
                            <strong>WhatsApp</strong>.
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            {" "}
                            A high quality YouTube{" "}
                            <strong>thumbnail image</strong>.
                          </p>
                        </li>
                      </ol>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="20"
                    >
                      What are the formats in which you deliver the video?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="20">
                    <Card.Body>
                      <p>
                        <strong>MP4</strong> or <strong>MOV</strong> file with
                        resolution of <strong>1920 x 1080 HD</strong> is the
                        usual standard format. But we can deliver in any HD
                        format that you prefer.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="21"
                    >
                      Who owns the rights to the videos?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="21">
                    <Card.Body>
                      <p>
                        You do. We may use the video for our own marketing, but
                        we would only do so with your approval given in writing.
                      </p>
                      <p>
                        Concepts and working files remain the property of
                        WowMakers. Customer may purchase video working files
                        from WowMakers for a fee of 20% of the project fee.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="22"
                    >
                      Will you provide the animation source files?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="22">
                    <Card.Body>
                      <p>
                        Concepts and raw or working files remain the property of
                        WowMakers. Customer may purchase video working files
                        from WowMakers for a fee of 20% of the project fee.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="23"
                    >
                      Is there a special rate for resellers?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="23">
                    <Card.Body>
                      <p>
                        Yes there is. Contact us to know more about these rates.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="24"
                    >
                      What tools do we use to create these explainer videos?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="24">
                    <Card.Body>
                      <p>We have quite a few tools in our arsenal:</p>
                      <ul>
                        <li>
                          <p className="faq-list">
                            For <strong>illustrations</strong> we use{" "}
                            <strong>Adobe illustrator</strong> and
                            <strong> Adobe Photoshop</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            For <strong>animation</strong> we use{" "}
                            <strong>Adobe After Effects</strong> and{" "}
                            <strong>Adobe Animate CC</strong>
                          </p>
                        </li>
                        <li>
                          <p className="faq-list">
                            For <strong>editing</strong> we use{" "}
                            <strong>Adobe Premier Pro</strong> and{" "}
                            <strong>Final Cut Pro</strong>
                          </p>
                        </li>
                      </ul>
                      <p>
                        Note: All the software we use are licensed &
                        premium/paid versions.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Title}
                      variant="link"
                      eventKey="25"
                    >
                      Do you provide white-label services?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="25">
                    <Card.Body>
                      <p>
                        Yes, we do. We can work as your backend partner. Your
                        agency will be the point of contact to your client and
                        we will be your creative partner.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvFaqContent
