import React from "react"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import EvFaqHeader from "../../components/explainer-video/faq/evFaqHeader"
import EvFaqContent from "../../components/explainer-video/faq/evFaqContent"
import DirectContact from "../../components/global/directContact"

import evFaqFeraturedImage from "../../../static/home/wowmakers.jpeg"

import '../../sass/pages/explainer-video-faq.scss'
import SeoHead from "../../components/global/seoHead"

export const Head = () => (
  <>
    <SeoHead
      ogImage={evFaqFeraturedImage}
      title="Frequently Asked Questions About Our Explainer Video Service"
      description="Find out more about explainer videos and its pricing, types and processes. This FAQ page is everything you need."
    />
  </> 
)

const EvFaq = () => {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions About Our Explainer Video Service"
        bclass="explainer-video-faq"
      />
      <ScrollIndicationHeightZero />
      <EvFaqHeader />
      <EvFaqContent />
      <DirectContact />
    </Layout>
  )
}

export default EvFaq
